import { CccLink, isInSpa } from './common/ccc-link.js'
import { preFetchOrGetPageUrl } from './common/preFetchOrGetPageUrl.js'

export function useLink({ sceneData, propData }) {

  const cccLink = new CccLink({ sceneData, propData })

  /**
   * 跳转页面
   */
  const jumpLink = ({ url, hrefType, routeType = 'push'}) => {
    if (url.indexOf('javascript:;') > -1) return

    if (isInSpa({ hrefType }) && hrefType !== 'activityNew') {
      if (window._gb_app_) {
        window._gb_app_?.$router?.[routeType](url)
      } else {
        // 兼容多页存在无_gb_app_实例case
        location.href = url
      }
    } else {
      sessionStorage.setItem('navtrack_to', 'page_campaigns')
      sessionStorage.setItem('navtrack_point', Date.now())
      location.href = preFetchOrGetPageUrl({ url, options: { isReturnUrl: true } })
    }
  }

  return {
    cccLink,
    jumpLink,
  }
}
