import { getQueryString } from '@shein/common-function'
import type { DS_TopBanner } from '../../../types'

export const topBannerFetch: DS_TopBanner.APIS_THIS['topBannerFetch'] = async function (channelId) {
  const contentId = getQueryString({ key: 'contentId' }) || ''
  const pageType = getQueryString({ key: 'pageType' }) || ''
  const result = await this.$schttp<Object>({
    url: '/ccc/common/top_banner',
    params: {
      pageType: 'topBanner',
      channelId,
      contentId: pageType === 'topBanner' ? contentId : '',
    },
  })
  return result.data
}
