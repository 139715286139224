import * as standard from './sources/standard'
import {
  organizeDataSource,
  createFsDataRunnerBySource,
} from '@shein-aidc/utils-data-source-toolkit'
import type { DS_TopBanner } from '../../types'

export function getSource({ dataSource = {}, appConfigs }: DS_TopBanner.SourceParams): DS_TopBanner.APIS {
  return organizeDataSource<DS_TopBanner.APIS, DS_TopBanner.DataSource>(
    {
      standard,
    },
    dataSource,
    appConfigs,
  )
}

export function getFsDataRunner(sourceParams: DS_TopBanner.FS_SourceParams) {
  const { topBannerFetch } = getSource(sourceParams)
  return createFsDataRunnerBySource<DS_TopBanner.FS_APIS, DS_TopBanner.FS_DATA>({
    topBannerFetch,
  })
}
