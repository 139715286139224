import type { AS_TopBanner } from '../../../types'

const standard: AS_TopBanner.AnalysisFuncs = {
  'click_block_main.comp_top-banner': ({ extraData }) => {
    const markMap = extraData.item?.markMap || {} // 中间层返回的埋点数据
    const { src_identifier } = markMap
    const hotZoneParams = extraData?.hotZoneParams || {}
    return {
      ...markMap,
      spm_new: {
        ...markMap?.spm_new,
        item_loc: extraData.index + 1,
        oprt_loc: extraData.compIndex + 1,
        ...hotZoneParams,
      },
      src_identifier:
        src_identifier?.join('`') +
        '`' +
        `ps=${extraData.compIndex + 1}_${extraData.index + 1}`,
    }
  },
  'expose_block_main.comp_top-banner': ({ extraData }) => {
    const markMap = extraData.item?.markMap || {} // 中间层返回的埋点数据
    const { src_identifier } = markMap
    const hotZoneParams = extraData?.hotZoneParams || {}
    return {
      ...markMap,
      spm_new: {
        ...markMap?.spm_new,
        item_loc: extraData.index + 1,
        oprt_loc: extraData.compIndex + 1,
        ...hotZoneParams,
      },
      src_identifier:
        src_identifier?.join('`') +
        '`' +
        `ps=${extraData.compIndex + 1}_${extraData.index + 1}`,
    }
  },
}

export default standard
